<template>
  <div class="oldvip">
    <h3>كونە مۇشەلەرگە</h3>
    <p class="desc">
      سالەمەتسىز. الاتاۋ كەسكىن كورۋ باعدارلاماسى سيستەمالىق جۇيەسىنىڭ
      وزگەرۋىنە بايلانىستى. بۇرىن الاتاۋ كەسكىن كورۋ باعدارلاماسىنان ەرەكشە
      مۇشەلىك اشتىرعان بولساڭىز جاڭا سيستەمانى ىستەتۋ بارىسىندا جالعاستى كۇشكە
      يە بولادى. ەگەردە اشتىرعان مۇشەلىگىڭىز 3 ايدان جوعارى بولسا جاڭا سيستەمانى
      ىستەتۋ بارىسىندا سىزگە 10 كۇن ارتىق قوسىلىپ بەرىلەدى. تەك تومەندەگى
      شارتتاردى ورىنداساڭىز بولعانى.
    </p>
    <p style="margin: 20px 0 0">
      جەكە مازمۇن ساتىپ العان بولساڭىزدا قابارلاسساڭىز بولادى.
    </p>
    <p style="margin: 20px 0">
      1. تومەندەگى ەكىلىكتى جايمالاپ تەحنيىكالىق جاۋاپتىمەن قابارلاسىڭىز.
    </p>
    <img class="qrCode" src="../../assets/musa.jpg" />

    <p style="margin: 20px 0">
      2. تەحنيكالىق جاۋاپتىنى قوسقان سوڭ قانشا ۋاقىتتىق مۇشەلىك اشتىرعانىڭىزدى،
      تەلەفون ءنومىرىڭىزدى جولداپ بەرەسىز.
    </p>
     <p style="margin: 20px 0">
      3. كونە سيستەماداعى جانە جاڭا سيستەماداعى مۇشەلىك ءنومىرىڭىزدى جولداپ بەرەسىز
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
img {
  pointer-events: auto; /* 禁止长按图片保存 */
}
.oldvip {
  direction: rtl;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    font-size: 30px;
    text-align: justify;
  }

  .qrCode {
    width: 500px;
    margin: 30px auto;
  }
}
</style>
